import {
	Component,
	OnInit
} from '@angular/core';
import {Router} from '@angular/router';
import {SupplierService} from '../home/services/supplier.service';
import {
	NTierMasterAdminService
} from '../tier1-visibility-survey/n-tier-master-admin/service/n-tier-master-admin.service';

@Component({
	selector: 'landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private masterAdminService: NTierMasterAdminService) {
	}

	isWppTabVisible = false;
	isVsmTabVisible = false;
	isProjectRSuppTabVisible = true;
	isSustainabilitySuppTabVisible = true;
	isSemiSuppTabVisible = false;
	isMasterTabVisible = false;
	isConsolidatedTabVisible = false;
	isT2RefreshTabVisible = true; //By default, since all suppliers has access to the button.
	isT2TabVisible = false; //By default, since all suppliers has access to the button.
	isSupplier = false;
	supplierEmailId = '';
	isT2ComplianceTabVisible = false;
	isClearToBuildTabVisible = false;

	ngOnInit() {
		this.supplierService.pushSelectedSurvey('home');
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			this.isMasterTabVisible = true;
			this.isConsolidatedTabVisible = false;
			this.isT2ComplianceTabVisible = false;
			this.isT2TabVisible = false; // de-commission t2 survey
			this.isT2RefreshTabVisible = true;
		}
		this.tabsVisibilityCheckForSuppliers();
	}

	tabsVisibilityCheckForSuppliers() {
		if ((sessionStorage.getItem('uid').includes('-'))) {
			// this.masterAdminService.isSupplierHasTigerSharkAccess(sessionStorage.getItem('emailAddress')).subscribe((tigerSharkAccess) => {
			// this.isWppTabVisible = tigerSharkAccess;
			// }); Disabling the WPP button
			//Disabling the ECST button
			// this.masterAdminService.isSupplierHasSemiSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((semiSupplierAccess) => {
			// if (semiSupplierAccess) {
			// 	this.isSemiSuppTabVisible = semiSupplierAccess;
			// } else {
			// tslint:disable-next-line:max-line-length
			// 	this.masterAdminService.isSupplierHasSeparateSemiconductorSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((semiconductorSupplierAccess) => {
			// 		this.isSemiSuppTabVisible = semiconductorSupplierAccess;
			// 	});
			// }
			// });
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isSupplierHasSustainabilityAccess(sessionStorage.getItem('emailAddress')).subscribe((susstainabilitySupplierAccess) => {
				this.isSustainabilitySuppTabVisible = susstainabilitySupplierAccess;
			});
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isSupplierHasProjectRNonMicrochipAccess(sessionStorage.getItem('emailAddress')).subscribe((projectRSupplierAccess) => {
				this.isProjectRSuppTabVisible = projectRSupplierAccess;
			});
			// tslint:disable-next-line:max-line-length
			// this.masterAdminService.isSupplierHasClearToBuildAccess(sessionStorage.getItem('emailAddress')).subscribe((clearToBuildSupplierAccess) => {
			// this.isClearToBuildTabVisible = clearToBuildSupplierAccess;
			// }); Disabling the CTB button
			// tslint:disable-next-line:max-line-length
			this.isVsmTabVisible = false;
			this.isSupplier = true;
			this.supplierEmailId = sessionStorage.getItem('emailAddress');
		}
	}

	onSubmitForMasterAdminDashboard() {
		// tslint:disable-next-line:max-line-length
		this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Main Admin Tab').subscribe(userHasMasterAdminAccess => {
			if (userHasMasterAdminAccess) {
				sessionStorage.setItem('Admin', 'NTierMasterAdmin');
				this.router.navigate(['/n-tier-master-admin']);
			} else {
				this.router.navigate(['/no-access-for-n-tier']);
			}
		});
	}

	onSubmitForConsolidatedDashboard() {
		// tslint:disable-next-line:max-line-length
		this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Smashed Report Screen').subscribe(userHasAccess => {
			if (userHasAccess) {
				sessionStorage.setItem('Admin', 'ConsolidatedDashboard');
				this.router.navigate(['/smashed-data-reporting']);
				// this.supplierService.pushSelectedSurvey('ConsolidatedDashboard');
			} else {
				this.router.navigate(['/no-access-for-n-tier']);
			}
		});

	}

	onSubmitForWPP() {
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			sessionStorage.setItem('Admin', 'WPP');
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'WPP Screen').subscribe(userHasAccess => {
				if (userHasAccess) {
					// this.supplierService.pushSelectedSurvey('WPP');
					this.router.navigate(['/tiger-shark-constraint-tracker']);
				} else {
					this.router.navigate(['/no-access-for-n-tier']);
				}
			});
		} else {
			this.masterAdminService.isSupplierHasTigerSharkAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
				if (userHasAccess) {
					// this.supplierService.pushSelectedSurvey('WPP');
					this.router.navigate(['/tiger-shark-constraint-tracker']);
				} else {
					this.router.navigate(['/no-access-for-n-tier']);
				}
			});
		}
	}

	onSubmitForClearToBuild() {
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			sessionStorage.setItem('Admin', 'clearToBuild');
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Super Admin').subscribe(userHasCtbSuperAdminAccess => {
				if (userHasCtbSuperAdminAccess) {
					this.router.navigate(['/clear-to-build-tracker']);
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build COE Admin').subscribe(userHasCtbCoeAdminAccess => {
						if (userHasCtbCoeAdminAccess) {
							this.router.navigate(['/clear-to-build-tracker']);
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build MP&L Admin').subscribe(userHasMpnlAdminAccess => {
								if (userHasMpnlAdminAccess) {
									this.router.navigate(['/clear-to-build-tracker']);
								} else {
									// tslint:disable-next-line:max-line-length
									this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build DRI Access').subscribe(userHasDriAdminAccess => {
										if (userHasDriAdminAccess) {
											this.router.navigate(['/clear-to-build-admin']);
										} else {
											// tslint:disable-next-line:max-line-length
											this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build MP&L Super Admin').subscribe(userHasMpnlSuperAdminAccess => {
												if (userHasMpnlSuperAdminAccess) {
													this.router.navigate(['/clear-to-build-tracker']);
												} else {
													// tslint:disable-next-line:max-line-length
													this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Screen').subscribe(userHasCtbScreenAccess => {
														if (userHasCtbScreenAccess) {
															this.router.navigate(['/clear-to-build-tracker']);
														} else {
															// tslint:disable-next-line:max-line-length
															this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Access Control Admin').subscribe(userHasCtbAccessControlAdminAccess => {
																if (userHasCtbAccessControlAdminAccess) {
																	this.router.navigate(['/n-tier-master-admin']);
																} else {
																	this.router.navigate(['/no-access-for-n-tier']);
																}
															});
														}
													});
												}
											});
										}
									});
								}
							});
						}
					});
				}
			});
		} else {
			this.masterAdminService.isSupplierHasClearToBuildAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
				if (userHasAccess) {
					this.router.navigate(['/clear-to-build-tracker']);
				} else {
					this.router.navigate(['/no-access-for-n-tier']);
				}
			});
		}
	}

	onSubmitForVSM() {
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			sessionStorage.setItem('Admin', 'VSM');
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'VSM Super Admin').subscribe(userHasVsmSuperAdminAccess => {
				if (userHasVsmSuperAdminAccess) {
					this.router.navigate(['/value-stream-data-collection-user']);
				} else {
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'VSM Screen').subscribe(userHasVsmAccess => {
						if (userHasVsmAccess) {
							// this.supplierService.pushSelectedSurvey('VSM');
							this.router.navigate(['/value-stream-data-collection-user']);
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'VSM Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
								if (userHasT2AccessControlAdminAccess) {
									this.router.navigate(['/n-tier-master-admin']);
								} else {
									this.router.navigate(['/no-access-for-n-tier']);
								}
							});
						}
					});
				}
			});
		} else {
			this.router.navigate(['/no-access-for-n-tier']);
		}
	}

	onSubmitForT2VisibilitySurvey() {
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			sessionStorage.setItem('Admin', 'T2Visibility');
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Super Admin').subscribe(userHasT2AdminAccess => {
				if (userHasT2AdminAccess) {
					this.router.navigate(['/t2-visibility-dashboard']);
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Screen').subscribe(userHasT2Access => {
						if (userHasT2Access) {
							this.router.navigate(['/t2-visibility-dashboard']);
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
								if (userHasT2AccessControlAdminAccess) {
									this.router.navigate(['/n-tier-master-admin']);
								} else {
									this.router.navigate(['/no-access-for-n-tier']);
								}
							});
						}
					});
				}
			});
		} else {
			this.router.navigate(['/t2-visibility']);
		}
	}

	onSubmitForT2VisibilityDataRefresh() {
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			sessionStorage.setItem('Admin', 'T2Visibility Data Refresh');
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Super Admin').subscribe(userHasT2AdminAccess => {
				if (userHasT2AdminAccess) {
					this.router.navigate(['/t2-visibility-data-refresh']);
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Screen').subscribe(userHasT2Access => {
						if (userHasT2Access) {
							this.router.navigate(['/t2-visibility-data-refresh']);
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
								if (userHasT2AccessControlAdminAccess) {
									this.router.navigate(['/n-tier-master-admin']);
								} else {
									this.router.navigate(['/no-access-for-n-tier']);
								}
							});
						}
					});
				}
			});
		} else {
			sessionStorage.setItem('Admin', 'T2Visibility Data Refresh');
			this.router.navigate(['/t2-visibility-data-refresh-spreedsheet']);
		}
	}

	onSubmitForProjectRNonMicrochip() {
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			sessionStorage.setItem('Admin', 'Project R');
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Project-R Non Microchip Survey Super Admin').subscribe(userHasSSAdminAccess => {
				if (userHasSSAdminAccess) {
					this.router.navigate(['/project-r-non-microchip']);
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Project-R Non Microchip Survey Screen').subscribe(userHasSSAccess => {
						if (userHasSSAccess) {
							this.router.navigate(['/project-r-non-microchip']);
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Project-R Non Microchip Survey Access Control Admin').subscribe(userHasSSAccessControlAdminAccess => {
								if (userHasSSAccessControlAdminAccess) {
									this.router.navigate(['/n-tier-master-admin']);
								} else {
									this.router.navigate(['/no-access-for-n-tier']);
								}
							});
						}
					});
				}
			});
		} else {
			sessionStorage.setItem('Admin', 'Project R');
			this.masterAdminService.isSupplierHasProjectRNonMicrochipAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
				if (userHasAccess) {
					console.log('If Supplier : Project R');
					// this.supplierService.pushSelectedSurvey('SemiSupp');
					this.router.navigate(['/project-r-non-microchip']);
				} else {
					this.router.navigate(['/no-access-for-n-tier']);
				}
			});
		}
	}

	onSubmitForSustainability() {
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			sessionStorage.setItem('Admin', 'sustainability survey');
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Sustainability Survey Super Admin').subscribe(userHasSSAdminAccess => {
				if (userHasSSAdminAccess) {
					this.router.navigate(['/sustainability-survey']);
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Sustainability Survey Screen').subscribe(userHasSSAccess => {
						if (userHasSSAccess) {
							this.router.navigate(['/sustainability-survey']);
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Sustainability Survey Access Control Admin').subscribe(userHasSSAccessControlAdminAccess => {
								if (userHasSSAccessControlAdminAccess) {
									this.router.navigate(['/n-tier-master-admin']);
								} else {
									this.router.navigate(['/no-access-for-n-tier']);
								}
							});
						}
					});
				}
			});
		} else {
			this.masterAdminService.isSupplierHasSustainabilityAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
				sessionStorage.setItem('Admin', 'sustainability survey');
				if (userHasAccess) {
					console.log('If Supplier : Sustainability');
					// this.supplierService.pushSelectedSurvey('SemiSupp');
					this.router.navigate(['/sustainability-survey']);
				} else {
					this.router.navigate(['/no-access-for-n-tier']);
				}
			});
		}
	}

	onSubmitForSemiSupp() {
		if (!(sessionStorage.getItem('uid').includes('-'))) {
			sessionStorage.setItem('Admin', 'SemiSupp');
			if (sessionStorage.getItem('employeeType') === 'F') {
				this.router.navigate(['/tier1-e-component-shortage/false']);
			} else {
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'E-Comp Shortage Super Admin').subscribe(userHasECompSuperAdminAccess => {
				if (userHasECompSuperAdminAccess) {
					this.router.navigate(['/tier1-e-component-shortage/false']);
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'E-Comp Shortage Admin').subscribe(userHasECompAdminAccess => {
						if (userHasECompAdminAccess) {
							this.router.navigate(['/tier1-e-component-shortage/false']);
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'E-Comp Shortage Access Control Admin').subscribe(userHasECompAccessControlAdminAccess => {
								if (userHasECompAccessControlAdminAccess) {
									this.router.navigate(['/n-tier-master-admin']);
								} else {
									this.router.navigate(['/no-access-for-n-tier']);
								}
							});
						}
					});
				}
			});
		}
		} else {
			this.masterAdminService.isSupplierHasSemiSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
				if (userHasAccess) {
					console.log('If Supplier : SemiSupp');
					// this.supplierService.pushSelectedSurvey('SemiSupp');
					this.router.navigate(['/tier1-e-component-shortage/true']);
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isSupplierHasSeparateSemiconductorSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasSemiconductorSupplierAccess) => {
						if (userHasSemiconductorSupplierAccess) {
							this.router.navigate(['/e-comp-semiconductor-supplier']);
						} else {
							this.router.navigate(['/no-access-for-n-tier']);
						}
					});
				}
			});
		}
	}

	onSubmitForT2VisibilityCompliance() {
		sessionStorage.setItem('Admin', 'T2VisibilityCompliance');
		this.router.navigate(['/qlik-sense-dashboard']);
	}
}
